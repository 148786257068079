import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

import { Layout} from '../components/common'
import { MetaData } from '../components/common/meta'
import { BlockSerializer } from "../utils/portableText/BlockSerializer"

/**
* Main index page (home page)
*
* Loads all posts and uses pagination to navigate through them.
* The number of posts that should appear per page can be setup
* in pagination.postsPerPage in siteSettings or /utils/siteConfig.js under `postsPerPage`.
*
*/
const Index = ({ data, location, pageContext }) => {
    const page = data.sanityPage

    return (
        <>
            <MetaData
                data={data}
                location={location}
                type="website"
            />
            <Helmet>
                {/*<style type="text/css">{`${page.codeinjection_styles}`}</style>*/}
            </Helmet>
            <Layout>
                <div className="container">
                    <article className="content">
                        <h1 className="content-title">{page.title}</h1>
                        <BlockSerializer pageSlug={page.slugWithRedirectValidation.slug.current}>{page._rawBody}</BlockSerializer>
                    </article>
                </div>
            </Layout>
        </>
    )
}

Index.propTypes = {
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
    pageContext: PropTypes.object,
}

export default Index

// This page query loads all posts sorted descending by published date
// The `limit` and `skip` values are used for pagination


export const pageQuery = graphql`
  query PostQuery {
    sanityPage(slugWithRedirectValidation: { slug: {current: {eq: "main"}}}) {
        ...SanityPageFields
    }
    allSanitySiteSettings {
        edges {
            node {
                authorSlug {
                    ...SlugWithRedirectValidationFields
                }
            }
        }
    }
  }
`
